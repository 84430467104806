import type { SVGAttributes } from 'react';

const WorkIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.1641 4.50008V2.33341H8.83073V4.50008H13.1641ZM2.33073 7.75008V17.5001C2.33073 18.0959 2.81823 18.5834 3.41406 18.5834H18.5807C19.1766 18.5834 19.6641 18.0959 19.6641 17.5001V7.75008C19.6641 7.15425 19.1766 6.66675 18.5807 6.66675H3.41406C2.81823 6.66675 2.33073 7.15425 2.33073 7.75008ZM19.6641 4.50008C20.8666 4.50008 21.8307 5.46425 21.8307 6.66675V18.5834C21.8307 19.7859 20.8666 20.7501 19.6641 20.7501H2.33073C1.12823 20.7501 0.164062 19.7859 0.164062 18.5834L0.174896 6.66675C0.174896 5.46425 1.12823 4.50008 2.33073 4.50008H6.66406V2.33341C6.66406 1.13091 7.62823 0.166748 8.83073 0.166748H13.1641C14.3666 0.166748 15.3307 1.13091 15.3307 2.33341V4.50008H19.6641Z"
      fill="currentColor"
    />
  </svg>
);

export default WorkIcon;

import { CompanyVendorFilters } from 'common/types/Vendor';
import { COMPANY_TABS_INDEX_MAP } from 'mappings/company';

export const coordVendorPath = (companyId: number, vendorId: string) =>
  `/companies/${companyId}/vendors/${vendorId}`;
export const coordVendorEditPath = (companyId: number, vendorId: string) =>
  `/companies/${companyId}/vendors/${vendorId}/edit-vendor`;
export const coordVendorAddPath = (companyId: number) =>
  `/companies/${companyId}/add-vendor`;

export const coordAllVendorsPath = '/all-vendors';
export const coordAllVendorsDetailsPath = (vendorId: string) =>
  `/all-vendors/${vendorId}`;
export const coordAllVendorsEditPath = (vendorId: string) =>
  `/all-vendors/${vendorId}/edit-vendor`;
export const coordAllVendorsEditDocumentPath = (
  vendorId: string,
  documentId: number,
) => `/all-vendors/${vendorId}/documents/${documentId}/edit-document`;
export const coordAllVendorsAddPath = `/all-vendors/add-vendor`;

export const coordAllUnclaimedVendorsAddPath =
  '/all-vendors/add-unclaimed-vendor';
export const coordAllUnclaimedVendorsEditPath = (unclaimedVendorId: number) =>
  `/all-vendors/unclaimed/${unclaimedVendorId}/edit`;
export const coordUnclaimedVendorsBulkImport =
  '/all-vendors/bulk-import-unclaimed';

export const coordCompanyCurrentVendorsPathWithFilters = (
  companyId: number,
  filters?: CompanyVendorFilters,
) =>
  `/companies/${companyId}?companyTab=${COMPANY_TABS_INDEX_MAP.CURRENT_VENDOR}&initialTableState=${encodeURIComponent(JSON.stringify(filters ?? {}))}`;

export const coordCompanyInvitedVendorsPathWithFilters = (
  companyId: number,
  filters?: CompanyVendorFilters,
) =>
  `/companies/${companyId}?companyTab=${COMPANY_TABS_INDEX_MAP.INVITED_VENDOR}&initialTableState=${encodeURIComponent(JSON.stringify(filters ?? {}))}`;

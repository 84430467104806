import { SVGAttributes } from 'react';

const VendorIQIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.6656 7.42667C10.7189 7.42667 11.5723 8.28 11.5723 9.33333C11.5723 10.3867 10.7189 11.24 9.6656 11.24C8.61227 11.24 7.75893 10.3867 7.75893 9.33333C7.75893 8.28 8.61227 7.42667 9.6656 7.42667Z"
      fill="currentColor"
    />
    <path
      d="M9.6656 0C14.6656 0 18.7323 3.92 18.9723 8.85333L21.5323 12.2667C21.8656 12.7067 21.5456 13.3333 20.9989 13.3333H18.9989V17.3333C18.9989 18.8 17.7989 20 16.3323 20H14.9989V24H5.6656V17.76C2.51893 16.2667 0.332266 13.0533 0.332266 9.33333C0.332266 4.17333 4.5056 0 9.6656 0ZM5.6656 9.33333C5.6656 9.50667 5.67893 9.68 5.69227 9.85334L4.5856 10.7333C4.47893 10.8133 4.45226 10.9467 4.51893 11.0667L5.5856 12.92C5.65227 13.04 5.79893 13.08 5.9056 13.04L7.2256 12.5067C7.5056 12.72 7.79893 12.8933 8.11893 13.0267L8.33227 14.44C8.3456 14.5733 8.4656 14.6667 8.59893 14.6667H10.7323C10.8656 14.6667 10.9723 14.5733 10.9989 14.44L11.1989 13.0267C11.5323 12.8933 11.8256 12.72 12.1056 12.5067L13.4256 13.04C13.5456 13.08 13.6923 13.04 13.7589 12.92L14.8256 11.0667C14.8923 10.96 14.8656 10.8133 14.7589 10.7333L13.6389 9.85334C13.6523 9.68 13.6656 9.50667 13.6656 9.33333C13.6656 9.16 13.6389 8.97333 13.6123 8.81333L14.7456 7.93333C14.8523 7.85333 14.8789 7.72 14.8123 7.58667L13.7456 5.74667C13.6789 5.62667 13.5456 5.58667 13.4256 5.62667L12.0923 6.16C11.8256 5.96 11.5189 5.77333 11.1989 5.64L10.9989 4.22667C10.9723 4.09333 10.8656 4 10.7323 4H8.59893C8.4656 4 8.35893 4.09333 8.33227 4.22667L8.13227 5.64C7.81227 5.77333 7.51893 5.94667 7.23893 6.16L5.9056 5.62667C5.7856 5.58667 5.63893 5.62667 5.5856 5.74667L4.51893 7.58667C4.45226 7.70667 4.47893 7.85333 4.5856 7.93333L5.71893 8.81333C5.67893 8.97333 5.6656 9.14667 5.6656 9.33333Z"
      fill="currentColor"
    />
  </svg>
);

export default VendorIQIcon;

import type { SVGAttributes } from 'react';

const FindVendorIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.66658 9.83333C12.2449 9.83333 14.3333 7.745 14.3333 5.16667C14.3333 2.58833 12.2449 0.5 9.66658 0.5C7.08825 0.5 4.99992 2.58833 4.99992 5.16667C4.99992 7.745 7.08825 9.83333 9.66658 9.83333ZM9.66658 2.83333C10.9499 2.83333 11.9999 3.88333 11.9999 5.16667C11.9999 6.45 10.9499 7.5 9.66658 7.5C8.38325 7.5 7.33325 6.45 7.33325 5.16667C7.33325 3.88333 8.38325 2.83333 9.66658 2.83333Z"
      fill="currentColor"
    />
    <path
      d="M2.66659 16.8333C2.92325 15.9933 6.52825 14.5 9.66658 14.5C9.66658 13.6833 9.81825 12.9017 10.0749 12.1783C6.88992 12.0617 0.333252 13.6483 0.333252 16.8333V19.1667H11.4633C10.8566 18.49 10.3783 17.7083 10.0749 16.8333H2.66659Z"
      fill="currentColor"
    />
    <path
      d="M20.6683 16.8567C21.0883 16.1683 21.3333 15.3633 21.3333 14.5C21.3333 11.9217 19.2449 9.83333 16.6666 9.83333C14.0883 9.83333 11.9999 11.9217 11.9999 14.5C11.9999 17.0783 14.0883 19.1667 16.6666 19.1667C17.5299 19.1667 18.3349 18.91 19.0233 18.5017C20.1083 19.5867 20.9133 20.3917 22.0216 21.5L23.6666 19.855C21.9166 18.105 22.7449 18.9217 20.6683 16.8567ZM16.6666 16.8333C15.3833 16.8333 14.3333 15.7833 14.3333 14.5C14.3333 13.2167 15.3833 12.1667 16.6666 12.1667C17.9499 12.1667 18.9999 13.2167 18.9999 14.5C18.9999 15.7833 17.9499 16.8333 16.6666 16.8333Z"
      fill="currentColor"
    />
  </svg>
);

export default FindVendorIcon;

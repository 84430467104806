import type { SVGAttributes } from 'react';

const CompanyIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="28"
    height="24"
    viewBox="0 0 28 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.0001 5.33333V0H0.666748V24H27.3334V5.33333H14.0001ZM6.00008 21.3333H3.33341V18.6667H6.00008V21.3333ZM6.00008 16H3.33341V13.3333H6.00008V16ZM6.00008 10.6667H3.33341V8H6.00008V10.6667ZM6.00008 5.33333H3.33341V2.66667H6.00008V5.33333ZM11.3334 21.3333H8.66675V18.6667H11.3334V21.3333ZM11.3334 16H8.66675V13.3333H11.3334V16ZM11.3334 10.6667H8.66675V8H11.3334V10.6667ZM11.3334 5.33333H8.66675V2.66667H11.3334V5.33333ZM24.6667 21.3333H14.0001V18.6667H16.6667V16H14.0001V13.3333H16.6667V10.6667H14.0001V8H24.6667V21.3333ZM22.0001 10.6667H19.3334V13.3333H22.0001V10.6667ZM22.0001 16H19.3334V18.6667H22.0001V16Z"
      fill="currentColor"
    />
  </svg>
);

export default CompanyIcon;

import type { SVGAttributes } from 'react';

const CheckListIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="28"
    height="22"
    viewBox="0 0 28 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.3334 5.04695H15.3334V7.71361H27.3334V5.04695ZM27.3334 15.7136H15.3334V18.3803H27.3334V15.7136ZM5.38675 10.3803L0.666748 5.66028L2.54675 3.78028L5.37341 6.60695L11.0267 0.953613L12.9067 2.83361L5.38675 10.3803ZM5.38675 21.0469L0.666748 16.3269L2.54675 14.4469L5.37341 17.2736L11.0267 11.6203L12.9067 13.5003L5.38675 21.0469Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckListIcon;

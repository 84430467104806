import { CompanyKnownForTags, CompanyPaymentTerms } from 'common/types/Company';

export const CompanyPaymentTermsLabels: {
  [key in CompanyPaymentTerms]: string;
} = {
  [CompanyPaymentTerms.INSTANT]: 'Instant',
  [CompanyPaymentTerms.NET_15]: 'Net 15',
  [CompanyPaymentTerms.NET_30]: 'Net 30',
  [CompanyPaymentTerms.NET_45]: 'Net 45',
  [CompanyPaymentTerms.NET_60]: 'Net 60',
  [CompanyPaymentTerms.OVER_NET_60]: 'Over Net 60',
  [CompanyPaymentTerms.UNDER_NET_15]: 'Under Net 15',
  [CompanyPaymentTerms.VARIABLE]: 'Variable',
};

export const CompanyKnownForLabels: {
  [key in CompanyKnownForTags]: string;
} = {
  [CompanyKnownForTags.ADMINISTRATIVE_SUPPORT]: 'Administrative Support',
  [CompanyKnownForTags.DAILY_JOBS]: 'Daily Jobs',
  [CompanyKnownForTags.MONTHLY_JOBS]: 'Monthly Jobs',
  [CompanyKnownForTags.MULTI_DAY_PROJECTS]: 'Multi-Day Projects',
  [CompanyKnownForTags.ON_SITE_SUPPORT]: 'On Site Support',
  [CompanyKnownForTags.PAID_TRIP_FEE]: 'Paid Trip Fee',
  [CompanyKnownForTags.PRE_APPROVED_BUDGET]: 'Pre-Approved Budget (NTE)',
  [CompanyKnownForTags.RECURRING_JOBS]: 'Recurring Jobs',
  [CompanyKnownForTags.RESPONSE_TIME_24_TO_48_HOURS]:
    'Response Time - 24-48 Hours',
  [CompanyKnownForTags.RESPONSE_TIME_OVER_48_HOURS]:
    'Response Time - Over 48 Hours',
  [CompanyKnownForTags.RESPONSE_TIME_UNDER_24_HOURS]:
    'Response Time - Under 24 Hours',
  [CompanyKnownForTags.STANDARD_PRICING]: 'Standard Pricing',
  [CompanyKnownForTags.TECHNOLOGY_TRAINING]: 'Technology Training',
};

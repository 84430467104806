import {
  CompanyKnownForLabels,
  CompanyPaymentTermsLabels,
} from 'common/mappings/company';
import { CompanyKnownForTags, CompanyPaymentTerms } from 'common/types/Company';

enum COMPANY_TABS {
  PROPERTIES = 'PROPERTIES',
  REQUESTED_VENDOR = 'REQUESTED_VENDOR',
  TOOLS = 'TOOLS',
  MARKETS = 'MARKETS',
  OPPORTUNITIES = 'OPPORTUNITIES',
  CURRENT_VENDOR = 'CURRENT_VENDOR',
  INVITED_VENDOR = 'INVITED_VENDOR',
  CATEGORIES = 'CATEGORIES',
  EXPORTS = 'EXPORTS',
}

export const COMPANY_TABS_INDEX_MAP: { [key in COMPANY_TABS]: number } = {
  PROPERTIES: 0,
  MARKETS: 1,
  OPPORTUNITIES: 2,
  CURRENT_VENDOR: 3,
  INVITED_VENDOR: 4,
  REQUESTED_VENDOR: 5,
  CATEGORIES: 6,
  TOOLS: 7,
  EXPORTS: 8,
};

export const paymentTermOptions = (
  Object.keys(CompanyPaymentTerms) as Array<keyof typeof CompanyPaymentTerms>
).map((key) => {
  return {
    label: CompanyPaymentTermsLabels[key],
    value: CompanyPaymentTerms[key],
  };
});

export const knownForOptions = (
  Object.keys(CompanyKnownForTags) as Array<keyof typeof CompanyKnownForTags>
).map((key) => {
  return {
    label: CompanyKnownForLabels[key],
    value: CompanyKnownForTags[key],
  };
});

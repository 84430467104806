export const coordWorkingList = `/working-list`;
export const coordWorkingListPreviouslyVerifiedDocuments = `/working-list?documentTab=2`;
export const coordWorkingListOpportunities = `/working-list?documentTab=4`;
export const coordWorkingListReviewDocument = (id: number) =>
  `/working-list/review/${id}`;
export const coordWorkingListVerifyDocument = (id: number) =>
  `/working-list/verify/${id}`;
export const coordWorkingListOpportunityPath = (
  companyId: number,
  id: number,
) => `/working-list/company/${companyId}/opportunity/${id}`;
export const coordWorkingListOpportunityCompanyViewPath = (
  companyId: number,
  id: number,
  queryParams?: Record<string, string>,
) =>
  `/working-list/company/${companyId}/opportunity/${id}/company-view${queryParams ? `?${new URLSearchParams(queryParams)}` : ''}`;
export const coordWorkingListOpportunityEditPath = (
  companyId: number,
  id: number,
) =>
  `/working-list/company/${companyId}/opportunity/${id}/company-view/edit-opportunity`;
export const coordWorkingListOpportunityApplicationPath = (
  companyId: number,
  opportunityId: number,
  opportunityVendorId: number,
) =>
  `/working-list/company/${companyId}/opportunity/${opportunityId}/company-view/applications/${opportunityVendorId}`;

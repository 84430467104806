import { SVGAttributes } from 'react';

const HandshakeIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="30"
    height="28"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.2964 24.4738C15.0564 24.7138 14.6297 24.7538 14.3497 24.4738C14.1097 24.2338 14.0697 23.8072 14.3497 23.5272L18.8697 19.0072L16.9897 17.1272L12.4697 21.6472C12.2164 21.9138 11.7897 21.9005 11.5231 21.6472C11.2431 21.3672 11.2831 20.9405 11.5231 20.7005L16.0431 16.1805L14.1631 14.3005L9.64305 18.8205C9.40305 19.0605 8.97639 19.1005 8.69639 18.8205C8.44305 18.5672 8.44305 18.1405 8.69639 17.8738L13.2164 13.3538L11.3231 11.4738L6.80305 15.9938C6.56305 16.2338 6.13639 16.2738 5.85639 15.9938C5.60305 15.7272 5.60305 15.3138 5.85639 15.0472L11.6964 9.20716L14.1897 11.6872C15.4564 12.9538 17.6431 12.9405 18.9097 11.6872C20.2164 10.3805 20.2164 8.27383 18.9097 6.96716L16.4297 4.48716L16.8031 4.11383C17.8431 3.07383 19.5364 3.07383 20.5764 4.11383L26.2297 9.76716C27.2697 10.8072 27.2697 12.5005 26.2297 13.5405L15.2964 24.4738ZM28.1097 15.4338C30.1897 13.3538 30.1897 9.98049 28.1097 7.88716L22.4564 2.23383C20.3764 0.153828 17.0031 0.153828 14.9097 2.23383L14.5364 2.60716L14.1631 2.23383C12.0831 0.153828 8.70972 0.153828 6.61639 2.23383L1.89639 6.95383C0.00305238 8.84716 -0.170281 11.7938 1.36305 13.8738L3.29639 11.9405C2.77639 10.9405 2.94972 9.67383 3.78972 8.83383L8.50972 4.11383C9.54972 3.07383 11.2431 3.07383 12.2831 4.11383L17.0297 8.8605C17.2697 9.1005 17.3097 9.52716 17.0297 9.80716C16.7497 10.0872 16.3231 10.0472 16.0831 9.80716L11.6964 5.43383L3.96305 13.1538C2.65639 14.4472 2.65639 16.5672 3.96305 17.8738C4.48305 18.3938 5.14972 18.7138 5.85639 18.8072C5.94972 19.5005 6.25639 20.1672 6.78972 20.7005C7.32305 21.2338 7.98972 21.5405 8.68305 21.6338C8.77639 22.3272 9.08305 22.9938 9.61639 23.5272C10.1497 24.0605 10.8164 24.3672 11.5097 24.4605C11.6031 25.1805 11.9231 25.8338 12.4431 26.3538C13.0697 26.9805 13.9097 27.3272 14.8031 27.3272C15.6964 27.3272 16.5364 26.9805 17.1631 26.3538L28.1097 15.4338Z"
      fill="currentColor"
    />
  </svg>
);

export default HandshakeIcon;

import type { SVGAttributes } from 'react';

const FancyDashboardIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.3336 0H2.65358C1.17358 0 0.0135775 1.18667 0.0135775 2.66667L0.000244141 21.3333C0.000244141 22.8 1.17358 24 2.65358 24H21.3336C22.8002 24 24.0002 22.8 24.0002 21.3333V2.66667C24.0002 1.18667 22.8002 0 21.3336 0ZM21.3336 16H16.0002C16.0002 18.2133 14.2002 20 12.0002 20C9.80025 20 8.00025 18.2133 8.00025 16H2.65358V2.66667H21.3336V16Z"
      fill="currentColor"
    />
  </svg>
);

export default FancyDashboardIcon;

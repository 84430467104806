import { SearchFilters } from 'common/types/Requests/Search';

export const searchPath = '/search';

export const searchPathWithFilters = (filters?: SearchFilters) => {
  if (!filters || Object.keys(filters).length === 0) {
    return searchPath;
  }
  return `/search?initialState=${encodeURIComponent(JSON.stringify(filters ?? {}))}`;
};

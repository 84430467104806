import type { SVGAttributes } from 'react';

const LocationIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="30"
    height="24"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M24.3335 5.33333H21.6668V8H24.3335V5.33333Z" fill="currentColor" />
    <path
      d="M24.3335 10.6667H21.6668V13.3333H24.3335V10.6667Z"
      fill="currentColor"
    />
    <path d="M24.3335 16H21.6668V18.6667H24.3335V16Z" fill="currentColor" />
    <path
      d="M0.333496 10.6667V24H8.3335V17.3333H11.0002V24H19.0002V10.6667L9.66683 4L0.333496 10.6667ZM16.3335 21.3333H13.6668V14.6667H5.66683V21.3333H3.00016V12L9.66683 7.33333L16.3335 12V21.3333Z"
      fill="currentColor"
    />
    <path
      d="M12.3335 0V2.62667L15.0002 4.53333V2.66667H27.0002V21.3333H21.6668V24H29.6668V0H12.3335Z"
      fill="currentColor"
    />
  </svg>
);

export default LocationIcon;
